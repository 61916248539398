import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getUserBoxOrders } from "../../redux/reducer/shipandShopOrdersReducer";
// 
import {
  HomeIcon,
  ShoppingCartIcon,
  BookOpenIcon,
  FolderMinusIcon,
  UserIcon,
  StarIcon,
  ComputerDesktopIcon,
  SpeakerWaveIcon,
  Bars3Icon,
  XMarkIcon,
  ChevronUpIcon,
  ChevronDownIcon
} from "@heroicons/react/24/outline";
import AdminInfoSection from "./AdminInfoSection";
import CartChooseButton from "../cartAddress/customButtonChooseCart";
import ImportantAdver from "../controlAccount/importantAdver";
import BoxSection from "../controlAccount/boxSection";
import TitleAndIcon from "../controlAccount/titleAndIcon";
import BlockedItems from "../controlAccount/blockedItems";
import ItemsBuyMeSection from "../buyForMe/itemsSection";
import FinishedOrders from "../finishedOrders/finishedOrdersSection";
import CustomOrders from "../myOrders/CustomOrder";
import BoxChooseButton from "../boxDetials/customButtonBox";
import CopyLinkWithoutBackground from "../iFrame/copyLinkWithoutBackground";
import CustomBuyForMeCardList from "../ordersForBuyForMe/listofCardsOrdersBuyForMe";
import { useTranslation } from "react-i18next";
import OrdersByUsers from "./OrdersByUsers";

const SidebarAdmin = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("HomeAdminSection");
  const [menuOpen, setMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.shipAndShop.orders);
  const orderCount = orders ? orders.length : 0;

  // useEffect(() => {
  //   dispatch(getUserBoxOrders());
  // }, [dispatch]);

  const handleMenuItemClick = (key) => {
    setSelectedMenuItem(key);
    setMenuOpen(false);
  };

  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
    };
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const menuItems = [
    { label: t("HomeAdminSection"), icon: HomeIcon, key: "HomeAdminSection" },
    {
      label: t("ProcurementClient"),
      icon: ShoppingCartIcon,
      key: "ProcurementClient",
      hasDropdown: true,
      subItems: [
        { label: t("OrdersByUsers"),icon: FolderMinusIcon, key: "OrdersByUsers" },
        { label: t("SubItem2"),icon: BookOpenIcon, key: "SubItem2" },
        { label: t("SubItem3"),icon: BookOpenIcon, key: "SubItem3" }
      ]
    },
    { label: t("CartChooseButton"), icon: BookOpenIcon, key: "CartChooseButton" },
    { label: t("ListOfBoxCardOrders"), icon: UserIcon, key: "ListOfBoxCardOrders" },
    { label: t("ItemsBuyMeSection"), icon: StarIcon, key: "ItemsBuyMeSection" },
    { label: t("Company"), icon: StarIcon, key: "Company" },
    { label: t("FinishedOrders"), icon: ComputerDesktopIcon, key: "FinishedOrders" },
    { label: t("CustomOrders"), icon: SpeakerWaveIcon, key: "CustomOrders" }
  ];

  const renderMenu = () => (
    <ul className="px-4 space-y-2 text-right">
      {menuItems.map((item) => (
        <div key={item.key} className="relative">
          <li
            className={`flex items-center justify-between px-4 py-2 text-black hover:bg-custom-gradient-div cursor-pointer ${
              selectedMenuItem === item.key ? "bg-custom-yellow font-bold" : ""
            }`}
            onClick={() => {
              if (item.hasDropdown) {
                setOpenDropdown((prev) => (prev === item.key ? null : item.key));
              } else {
                handleMenuItemClick(item.key);
              }
            }}
          >
            <div className="flex items-center gap-2">
              <item.icon className="w-5 h-5" />
              {item.label}
            </div>
            {item.hasDropdown && (
              <span className="text-xs">{openDropdown === item.key ? 
                <ChevronUpIcon class="h-5 w-5 text-black" />
                :
                <ChevronDownIcon class="h-5 w-5 text-black" />
                }</span>
            )}
          </li>

          {item.hasDropdown && openDropdown === item.key && (
            <ul className="pr-6 mt-1 space-y-1">
              {item.subItems.map((subItem) => (
                <li
                  key={subItem.key}
                  className={`text-sm cursor-pointer px-2 py-1 rounded hover:bg-custom-gradient-div ${
                    selectedMenuItem === subItem.key ? "bg-custom-yellow font-bold" : ""
                  }`}
                  onClick={() => handleMenuItemClick(subItem.key)}
                >
                  <div className="flex items-center gap-2">
                    <subItem.icon className="w-5 h-5" />
                    {/* {item.label} */}
                   {subItem.label}
                  </div>
                </li>
              ))}
            </ul>
          )}

          {item.key === "ListOfBoxCardOrders" && orderCount > 0 && (
            <span className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full top-2 left-2">
              {orderCount}
            </span>
          )}
        </div>
      ))}
    </ul>
  );

  return (
    <div className="flex-col md:flex" dir={direction}>
      <div className="w-full md:flex">
        <div className="w-1/4">
          <div className="flex items-center justify-between p-4 md:hidden">
            <button onClick={() => setMenuOpen(!menuOpen)} className="text-black">
              {menuOpen ? <XMarkIcon className="w-6 h-6" /> : <Bars3Icon className="w-6 h-6" />}
            </button>
          </div>

          <div
            className={`fixed top-0 left-0 z-50 w-1/2 h-full bg-white shadow-lg transform transition-all duration-300 ease-in-out ${
              menuOpen ? "translate-x-0" : "-translate-x-full"
            } md:hidden`}
          >
            {renderMenu()}
          </div>

          <div className="hidden md:block">
            <div className="flex flex-col w-full min-h-[70vh] p-2 pt-5 bg-white rounded-md shadow-md h-fit">
              {renderMenu()}
            </div>
          </div>
        </div>

        <div className="md:w-3/4">
          {selectedMenuItem === "HomeAdminSection" && <div className="mt-5 md:mx-5 md:mt-10"><AdminInfoSection /></div>}
          {selectedMenuItem === "ControlBoxSection" && <div className="mt-5 md:mx-5 md:mt-10"><TitleAndIcon /><BoxSection /><ImportantAdver /><BlockedItems /></div>}
          {selectedMenuItem === "OrdersByUsers" && <div className="mt-5 md:mx-5 md:mt-10"><h2><OrdersByUsers /></h2></div>}
          {selectedMenuItem === "SubItem2" && <div className="mt-5 md:mx-5 md:mt-10"><h2>Sub Item 2</h2></div>}
          {selectedMenuItem === "SubItem3" && <div className="mt-5 md:mx-5 md:mt-10"><h2>Sub Item 3</h2></div>}
          {selectedMenuItem === "CartChooseButton" && <div className="mt-5 md:mx-5 md:mt-10"><CartChooseButton /></div>}
          {selectedMenuItem === "ListOfBoxCardOrders" && <div className="mt-5 md:mx-5 md:mt-10"><BoxChooseButton /></div>}
          {selectedMenuItem === "ItemsBuyMeSection" && <div className="mt-5 md:mx-5 md:mt-10"><ItemsBuyMeSection /><CopyLinkWithoutBackground /></div>}
          {selectedMenuItem === "Company" && <div className="mt-5 md:mx-5 md:mt-10"><CustomBuyForMeCardList /></div>}
          {selectedMenuItem === "FinishedOrders" && <div className="mt-5 md:mx-5 md:mt-10"><FinishedOrders /></div>}
          {selectedMenuItem === "CustomOrders" && <div className="mt-5 md:mx-5 md:mt-10"><CustomOrders /></div>}
        </div>
      </div>
    </div>
  );
};

export default SidebarAdmin;
