import React, { useState } from 'react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';

const BillingDetailsTable = ({ details, onDelete, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form, setForm] = useState({
    name: '',
    weight: '',
    price: '',
    DShippingFees: '',
    timeLeft: '',
    category: '',
    brand: '',
    area: '',
    currency: '',
  });

  const headers = [
    'Number',
    'Name',
    'Phone',
    'Box',
    'Link',
    'Size',
    'Count',
    'History',
    'Actions',
  ];

  const values = [
    details.number,
    details.Name,
    details.phone,
    details.Box,
    details.link,
    details.Size,
    details.Count,
    details.history,
  ];

  const handleEdit = () => {
    setForm({
      name: details.Name,
      weight: details.weight || '',
      price: details.price || '',
      DShippingFees: details.DShippingFees || '',
      timeLeft: details.timeLeft || '',
      category: details.category || '',
      brand: details.brand || '',
      area: details.area || '',
      currency: details.currency || '',
    });
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(form);
    setIsEditing(false);
  };

  return (
    <div className="p-4">
      <table className="w-full">
        <thead>
          <tr className="text-sm text-black bg-custom-yellow">
            {headers.map((header, index) => (
              <th key={index} className="px-4 py-2 font-bold text-center">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="text-sm text-gray-800 bg-white">
            {values.map((value, index) => (
              <td key={index} className="px-4 py-2 text-center">
                {value}
              </td>
            ))}
            <td className="px-4 py-2 text-center">
              <div className="flex justify-between space-x-2">
                <button onClick={handleEdit} className="text-blue-600 hover:text-blue-800">
                  <PencilSquareIcon className="w-5 h-5 text-black" />
                </button>
                <button onClick={onDelete} className="text-red-600 hover:text-red-800">
                  <TrashIcon className="w-5 h-5 text-black" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {isEditing && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-2 gap-4 p-6 bg-white rounded-lg shadow-lg w-[90%] max-w-2xl"
          >
            {[
              'name',
              'weight',
              'price',
              'DShippingFees',
              'timeLeft',
              'category',
              'brand',
              'area',
              'currency',
            ].map((field) => (
              <div key={field} className="flex flex-col">
                <label className="text-sm font-semibold capitalize">{field}</label>
                <input
                  type="text"
                  name={field}
                  value={form[field]}
                  onChange={handleChange}
                  required
                  className="px-3 py-1 border rounded-md"
                />
              </div>
            ))}

            <div className="flex justify-end col-span-2 mt-2 space-x-3">
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="px-4 py-1 text-black bg-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-1 text-white bg-blue-600 rounded-md"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default BillingDetailsTable;
