import React, { useState } from 'react';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';

const CustomBuyForMeCard = ({ image, title, size,link, count, price, isEditable,status ,checked,onChange ,del}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const buttonLink = (() => {
        try {
            const url = new URL(link);
            const domain = url.hostname.replace('www.', ''); // zara.com
            return domain.split('.')[0]; // zara
        } catch {
            return 'Invalid Link';
        }
    })();
    
    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="relative flex flex-col items-center justify-center w-full overflow-hidden bg-white border group"
        >  
            <div className="relative w-full h-[200px] flex items-center justify-center">
          
                <img
                    src={image}
                    alt={title}
                    className="object-contain max-h-full"
                />
                {isHovered && isEditable && (
                    <div className="absolute flex justify-between w-full gap-2 top-2 px-[5%]">
                        <button className="p-2 rounded-lg bg-custom-yellow">
                            <PencilIcon className="w-4 h-4 text-black" />
                        </button>
                        <button className="p-2 bg-black rounded-lg"
                        onClick={del}
                        >
                            
                            <XMarkIcon className="w-4 h-4 text-white" />
                        </button>
                    </div>
                )}
            </div>
            <div
                className={`flex flex-col items-start justify-center w-full p-4 transition-colors duration-300 ${
                    isHovered ? 'bg-black text-white' : 'bg-custom-yellow text-black'
                }`}
            >
            <h2 className="overflow-hidden text-sm font-bold text-ellipsis whitespace-nowrap">{title}</h2>
            
            <div className='flex justify-between w-full'>
            <p className="text-xs">Size: {size}</p>
            <p className="text-xs">Count: {count}</p>
            </div>
            <p className='text-xs'>{status}</p>

                <p className="mt-2 text-lg font-bold">{price} TL</p>
        <div className='flex justify-between w-full'>
            <div className='flex'>
                <input
                        className='w-5 h-5'
                        type="checkbox"
                        checked={ checked}
                        onChange={onChange}
                    />
                <span className="ms-2">Check</span>
            </div>
            <div>
                <a href={link}  target="_blank" rel="noopener noreferrer" className="px-3 py-1 text-white duration-300 bg-black rounded-lg group-hover:bg-custom-yellow group-hover:text-black">
                    {buttonLink}
                </a>
            </div>
        </div>
            </div>
        </div>
    );
};

export default CustomBuyForMeCard;
