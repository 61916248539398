import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from '../constent';
export const createAddress = createAsyncThunk(
  "userAddress/createAddress",
  async (addressData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) throw new Error("No token found");

      const response = await axios.post(`${BASE_URL}/user-address`, addressData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to create address");
    }
  }
);



export const getAddressById = createAsyncThunk(
  "userAddress/getAddressById",
  async (addressId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/${addressId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Address not found");
    }
  }
);

export const updateAddressById = createAsyncThunk(
  "userAddress/updateAddressById",
  async ({ addressId, updatedData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/${addressId}`, updatedData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to update address");
    }
  }
);

export const deleteAddressById = createAsyncThunk(
  "userAddress/deleteAddressById",
  async (addressId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/${addressId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return { addressId, status: response.data.status };
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to delete address");
    }
  }
);
// get data user 
export const getAddressesByUserId = createAsyncThunk(
  "userAddress/getAddressesByUserId",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        console.error("Access token not found");
        throw new Error("Access token not found");
      }
// console.log(token)
      const response = await axios.get(`${BASE_URL}/myData`, {
        headers: { token: token }, // Only using token
      });


      if (!response.data || typeof response.data !== "object") {
        throw new Error("Invalid response format");
      }
      // console.log(response.data)
      return response.data;
    } catch (error) {
      console.error("Error fetching addresses:", error);
      return rejectWithValue(error.response?.data || "No addresses found");
    }
  }
);


export const updateUserAddress = createAsyncThunk(
  "userAddress/updateUserAddress",
  async ({ userId, updatedAddress }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        console.error("Access token not found");
        throw new Error("Access token not found");
      }

      const response = await axios.put(
        `${BASE_URL}/myData/address/${userId}`,
        updatedAddress,
        {
          headers: { token: token },
        }
      );

      console.log("Update Response:", response.data);

      if (!response.data || typeof response.data !== "object") {
        throw new Error("Invalid response format");
      }

      return response.data;
    } catch (error) {
      console.error("Error updating address:", error);
      return rejectWithValue(error.response?.data || "Failed to update address");
    }
  }
);

// update name & phone
export const updateUserById = createAsyncThunk(
  "userAddress/updateUserById",
  async ({ userId, updatedData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) throw new Error("No token found");

      // console.log(userId)
      const response = await axios.put(`${BASE_URL}/user/${userId}`, updatedData, {
        headers: { token:token },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to update user");
    }
  }
);

// update Password
export const updateUserPassword = createAsyncThunk(
  "userAddress/updateUserPassword",
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) throw new Error("No token found");
      const response = await axios.post(
        `${BASE_URL}/auth/changePassword`,
        { oldPassword, newPassword },
        {
          headers: { token: token },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to update password");
    }
  }
);


// Slice
const userAddressSlice = createSlice({
  name: "userAddress",
  initialState: {
    addresses: [],
    currentAddress: null,
    loading: false,
    error: null,
    successMessage: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    // Create Address
    builder.addCase(createAddress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAddress.fulfilled, (state, action) => {
      state.loading = false;
      state.successMessage = "Address created successfully!";
      state.addresses.push(action.payload);
    });
    builder.addCase(createAddress.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Get Address by ID
    builder.addCase(getAddressById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAddressById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentAddress = action.payload;
    });
    builder.addCase(getAddressById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Update Address by ID
    builder.addCase(updateAddressById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAddressById.fulfilled, (state, action) => {
      state.loading = false;
      state.successMessage = "Address updated successfully!";
      state.addresses = state.addresses.map((address) =>
        address.id === action.payload.id ? action.payload : address
      );
    });
    builder.addCase(updateAddressById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete Address by ID
    builder.addCase(deleteAddressById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAddressById.fulfilled, (state, action) => {
      state.loading = false;
      state.successMessage = "Address deleted successfully!";
      state.addresses = state.addresses.filter(
        (address) => address.id !== action.payload.addressId
      );
    });
    builder.addCase(deleteAddressById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Get Addresses by User ID
    builder.addCase(getAddressesByUserId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAddressesByUserId.fulfilled, (state, action) => {
      state.loading = false;
      state.addresses = action.payload;
    });
    builder.addCase(getAddressesByUserId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // Get data by User ID for (update name & phone)
    builder.addCase(updateUserById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserById.fulfilled, (state, action) => {
      state.loading = false;
      state.successMessage = "User updated successfully!";
      
      // Update data directly
      state.addresses = action.payload;
    });
    builder.addCase(updateUserById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    
    // Get data by User ID for (update password)
    builder.addCase(updateUserPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.successMessage = "Password updated successfully!";
    });
    builder.addCase(updateUserPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});


// Export actions and reducer
export const { clearError, clearSuccessMessage } = userAddressSlice.actions;
export default userAddressSlice.reducer;
