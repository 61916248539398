import React, { useContext } from "react";
import { MenuItem } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../auth/AuthContext";

const CustomUnloginMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
    navigate("/"); // إعادة التوجيه إلى الصفحة الرئيسية بعد تسجيل الخروج
  };

  return (
    <div className="w-full">
      {isLoggedIn ? (
        <>
          <MenuItem>
            <button
              onClick={() => navigate("/AccountUser")}
              className="block w-full px-4 py-2 text-sm font-medium text-gray-700 rounded-lg md:font-bold md:text-sm hover:bg-gray-100"
            >
              {t("MyAccount")}
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={handleLogout}
              className="block w-full px-4 py-2 text-sm font-medium text-gray-700 rounded-lg md:font-bold md:text-sm hover:bg-gray-100"
            >
              {t("logout")}
            </button>
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem>
            <button
              onClick={() => navigate("/LoginPage")}
              className="block w-full px-4 py-2 text-sm font-medium text-gray-700 rounded-lg md:font-bold md:text-sm hover:bg-gray-100"
            >
              {t("login")}
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={() => navigate("/RegistrationPage")}
              className="block w-full px-4 py-2 text-sm font-medium text-gray-700 rounded-lg md:font-bold md:text-sm hover:bg-gray-100"
            >
              {t("register")}
            </button>
          </MenuItem>
        </>
      )}
    </div>
  );
};

export default CustomUnloginMenu;
