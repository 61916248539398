import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserIcon } from "@heroicons/react/24/outline";
import CustomTitleAccount from "./customTitleAccount";
import CustomInfoInput from "./customInfoInput";
import { useTranslation } from "react-i18next";
import { getAddressesByUserId } from "../../redux/reducer/userAddressReducer";
import CustomContainer from "./customContainer";

const AdminInfoSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [firstValue, setFirstValue] = useState(0);


  const { addresses, loading, error } = useSelector((state) => state.userAddress);
  
  // console.log({ addresses, loading, error })
  useEffect(() => {
    dispatch(getAddressesByUserId());
  }, [dispatch]);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      const user = addresses[0]; // Access the first item
      setFirstValue(user.name || ""); // Adjust key names based on the API
      // setPhone(user.phoneNumber || ""); // Adjust key names based on the API
    }
  }, [addresses]);
  
  const handleClick = () => {
    console.log("Button clicked!");
  };

  return (
    <div className="w-full">
      {/* <CustomTitleAccount
        title={t("AccountInformation")}
        onClick={handleClick}
        icon={<UserIcon className="w-8 h-8 text-black" />}
      /> */}
      {loading && <p>{t("Loading...")}</p>}
      {error && <p className="text-red-500">{t("Error fetching data")}</p>}
      {!loading && !error && addresses && (
        <div className="flex flex-col space-y-5">
          {/* <CustomInfoInput
            id="firstName"
            label={t("Name")}
            type="text"
            placeholder={t("Name")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            icon={<i className="fas fa-user"></i>}
          />
          <CustomInfoInput
            id="phone"
            label={t("PhoneNumber")}
            type="text"
            placeholder={t("PhoneNumber")}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            icon={<i className="fas fa-phone"></i>}
          /> */}
          <div className="flex w-full gap-4">
            <CustomContainer 
            title={t("NumberOfOrdersInProgress")}
            value={firstValue}
            />
            <CustomContainer 
            title={t("RequestsAwaitingPricing")}
            value={firstValue}
            />
          </div>

          
          <div className="flex w-full gap-4">
            <CustomContainer 
            title={t("NumberOfOrdersInProgress")}
            value={firstValue}
            />
            <CustomContainer 
            title={t("RequestsAwaitingPricing")}
            value={firstValue}
            />
            <CustomContainer 
            title={t("RequestsAwaitingPricing")}
            value={firstValue}
            />
            <CustomContainer 
            title={t("RequestsAwaitingPricing")}
            value={firstValue}
            />
          </div>
        </div>
      )}
    </div>
  );
};
 
export default AdminInfoSection;
 