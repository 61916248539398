import React, { useEffect, useState } from "react";
import FooterUser from "../component/sharedComponents/footer";
import SidebarUser from "../component/accountUser/sideBarUsers";
import { useNavigate } from "react-router-dom";
import SidebarAdmin from "../component/accountAdmin/sideBarAdmin";
import HeaderDashboard from "../component/sharedComponents/HeaderDashboard";

const Dashboard = () => {
    const navigate = useNavigate();
    const [tokenFound, setTokenFound] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("accessToken"); // Adjust this based on where your token is stored
        if (token) {
            setTokenFound(true);
        } 
        else if (!tokenFound) {
            navigate("/LoginPage", { state: true  }); 
        }
        else {
            // Redirect or handle token not found scenario
            console.log("Token not found"); 
        }
    }, []);

    

    return (
        <div>
            <HeaderDashboard />
            <div className="mt-[55px] md:mt-[54px] lg:mt-[75px]">
                <SidebarAdmin />
            </div>
            <FooterUser />
        </div>
    );
};

export default Dashboard;
