import React ,{useState ,useEffect} from "react";

export default function CustomContainer({
  title,
  value,
}) {
  const [direction, setDirection] = useState(
          localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
        );
        
        useEffect(() => {
          const handleStorageChange = () => {
            setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
          };
        
          window.addEventListener("storage", handleStorageChange);
          return () => window.removeEventListener("storage", handleStorageChange);
        }, []);
        
        useEffect(() => {
          const language = localStorage.getItem("language");
          setDirection(language === "ar" ? "rtl" : "ltr");
        }, [localStorage.getItem("language")]); 
        
        
  return (
    <div className="relative w-full group">
      <div className="flex justify-center py-4 bg-white">
        <h1>{title}</h1>
      </div>
      <div className="flex justify-center duration-300 bg-white group-hover:bg-custom-yellow">
        <h1 className="text-2xl">{value}</h1>
      </div>
    </div>
  );
}
