import React from "react";
import image from '../../assets/images/Vector (4).png';

const CustomOrderInstructionCard = ({ title }) => {
    return (
        <div className="flex items-center justify-center mt-8 bg-white">
            <div className="p-4 text-center">
                <img
                    src={image}
                    alt="image"
                    className="w-12 h-12 mx-auto"
                />
                <p className="mt-2 text-lg text-black">
                    {title}
                </p>
            </div>
        </div>
    );
};

export default CustomOrderInstructionCard;
