import React, { useEffect,useState } from "react";
import {
  UserIcon,
  ShoppingCartIcon,
  Bars4Icon,
  XMarkIcon,
  BellIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import CustomButton from "./customButton";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import NavBarMenu from "./navBarMenu";
import CustomDropDownNavBar from "./customDropDownNavBar";
import LanguageToggleButton from "./languageSwitcherFlags";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AvaterImage from '../../assets/images/av.jpg';
import CustomMenu from "./customMneu";
import NotificationButton from './notificationList';
import NavBarMenuContactUsOurService from './navContactUsOurService';
const HeaderDashboard = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 1024);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    i18n.changeLanguage(savedLanguage);
    document.documentElement.dir = savedLanguage === "ar" ? "ltr" : "rtl";
  }, [i18n]);

  const handleNavigation = () => {
    navigate('/ContactUsPage', { replace: true });
    window.location.reload(); // Force reload after navigation
  };

  
  const logoStyle = {
    height: isMobile ? "40px" : "60px",
    width: isMobile ? "100px" : "161px",
  };
  const iconSize = isMobile ? "w-5 h-5" : "w-6 h-6";


  const [direction, setDirection] = useState(
        localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
      );
      
      useEffect(() => {
        const handleStorageChange = () => {
          setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
        };
      
        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
      }, []);
      
      useEffect(() => {
        const language = localStorage.getItem("language");
        setDirection(language === "ar" ? "rtl" : "ltr");
      }, [localStorage.getItem("language")]); 
      
      
  return (
    <div
    className="fixed top-0 left-0 z-50 w-full px-4 py-2 text-white bg-black shadow-md ">
        <div className="flex flex-row items-center justify-between">
        {/* Icons Section */}
        <div className="flex items-center justify-center gap-2 space-x-4 md:gap-5">
          {/* Mobile Menu Button */}
          {isMobile && (
            <button onClick={() => setMenuOpen(!menuOpen)} >
              {menuOpen ? (
                <XMarkIcon className="w-8 h-8 text-white" />
              ) : (
                <Bars4Icon className="w-8 h-8 text-white" />
              )}
            </button>
          )}

          {/* <div className="block " style={{marginLeft:0}}>
            <button 
                onClick={handleNavigation} 
                dir="ltr"
                className="z-0 items-center justify-center hidden px-3 py-2 text-xs font-medium text-black transition duration-300 ease-in-out transform sm:inline-flex md:text-base lg:px-4 lg:py-2 bg-custom-yellow rounded-custom sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color whitespace-nowrap "
              >
        
            {PhoneIcon && (
        <PhoneIcon className="w-3 ml-1 mr-1 text-black lg:w-4" />
              
        )}
        {t("contact_us")}
      </button>
          </div> */}

          <CustomMenu/>

          <NotificationButton/>
          <LanguageToggleButton />
        </div>

        {/* Logo Section */}
        <div>
          <a href="/">
            <img src={Logo} alt="Logo"  style={logoStyle} />
          </a>
        </div>
      </div>        
    </div>
  );
};

export default HeaderDashboard;

