import React, { useEffect, useState } from "react";
import Header from "../component/sharedComponents/header";
import FooterUser from "../component/sharedComponents/footer";
import SidebarUser from "../component/accountUser/sideBarUsers";
import { useNavigate } from "react-router-dom";


const  AccountUser = () => {
    const navigate = useNavigate();
    const [tokenFound, setTokenFound] = useState(false);

     useEffect(() => {
            const token = localStorage.getItem("accessToken"); // Adjust this based on where your token is stored
            if (token) {
                setTokenFound(true);
            } 
            else if (!tokenFound) {
                navigate("/LoginPage", { state: true  }); 
            }
            else {
                // Redirect or handle token not found scenario
                console.log("Token not found"); 
            }
        }, []);

    if (!tokenFound) {
        navigate("/LoginPage", { state: true  }); 

        // return null;
    }

    return (
        <div>
            {/*  */}
            <Header />
            <div className="mt-[55px] md:mt-[56px] lg:mt-[75px] ">
                <SidebarUser />
            </div>
            <FooterUser />
        </div>
    );
};

export default AccountUser;
