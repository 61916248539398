import React,{useEffect} from 'react'
import BillingDetailsTable from './DetailsTable';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllOrders } from '../../redux/reducer/adminReducer';
    
const OrdersByUsers = () => {

  const dispatch = useDispatch();
  const { allOrders, loading, error } = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(fetchAllOrders());
  }, [dispatch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  
    return (
        <div className="mx-auto rounded-xl">
             {allOrders.map((order) => (
        <BillingDetailsTable
        details={{
            number: 1,
            Name: 'Product A',
            phone: '+1 234 567',
            Box: 'Box-123',
            link: 'https://example.com',
            Size: 'Medium',
            Count: 10,
            history: '3 changes',
            weight: '1kg',
            price: '$100',
            DShippingFees: '$10',
            timeLeft: '2 days',
            category: 'Electronics',
            brand: 'BrandX',
            area: 'USA',
            currency: 'USD',
        }}
        onDelete={() => alert('Deleted!')}
        onUpdate={(data) => console.log('Updated:', data)}
        />
      ))}
           


        </div>
      );
}

export default OrdersByUsers
