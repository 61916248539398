import React, { useEffect, useState ,useRef} from 'react';
import axios from 'axios';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import CustomLines from './customLines';
import CustomBigText from '../sharedComponents/customBigText';
import CustomCategoryCard from '../sharedComponents/customCategoryCards';
import { useTranslation } from 'react-i18next';
import API_URL from "../../redux/constent";
import BoxSection2 from "../../assets/images/BoxSection2.png"
const ChooseItemsSection = () => {
  const { i18n,t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const scrollContainerRef = useRef(null);

  const [direction, setDirection] = useState(
    localStorage.getItem("language") === "ar" ? "rtl" : "ltr"
  );
  
  useEffect(() => {
    const handleStorageChange = () => {
      setDirection(localStorage.getItem("language") === "ar" ? "rtl" : "ltr");
    };
  
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  
  useEffect(() => {
    const language = localStorage.getItem("language");
    setDirection(language === "ar" ? "rtl" : "ltr");
  }, [localStorage.getItem("language")]); 

  
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/shoppingOption`);
        // console.log('API Response:', response.data.data); // Debug API response
        
        // Check if response.data contains an array or an object
        if (Array.isArray(response.data.data)) {
          setCategories(response.data.data); // If API returns an array directly
        } else if (response.data.data.category && Array.isArray(response.data.category)) {
          setCategories(response.data.data.category); // If API wraps the array inside an object
        } else {
          console.error('Unexpected API response format:', response.data.data);
          setCategories([]); // Prevent map() errors
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories([]);
      }
    };
  
    fetchCategories();
  }, []);
   // Scroll Function
   const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300; // Adjust scroll step
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const scrollStep = 220; // Adjust this value for smooth scrolling
    const intervalTime = 3000; // 3 seconds

    const interval = setInterval(() => {
      if (scrollContainer) {
        if (
          scrollContainer.scrollLeft + scrollContainer.clientWidth >=
          scrollContainer.scrollWidth
        ) {
          scrollContainer.scrollLeft = 0; // Reset to the beginning when reaching the end
        } 
        else {
          scrollContainer.scrollLeft += scrollStep; // Move right
        }
      }
    }, intervalTime);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // console.log(categories)
  return (
    <div className='relative' dir="ltr">
      <img 
        src={BoxSection2} 
        alt="Background" 
        className="absolute top-0 left-0 w-14 md:w-20"
      />
      <div className="relative z-20 flex flex-col items-center justify-center text-center max-container">
        <CustomLines />
        <div>
          <CustomBigText title={t('shoppingSection.title')} />
          <p className="mt-4 font-medium text-gray-700" dir={direction}>{t('shoppingSection.description')}</p>
        </div>

        {/* Slider Container */}
        <div className="relative w-full mt-10 mb-10 ">
          {/* Left Scroll Button */}
          <button
            onClick={() => scroll("left")}
            className="absolute z-10 p-2 -translate-y-1/2 bg-white duration-300 hover:bg-custom-yellow top-1/2 rounded-full shadow-md  left-[0px] group hidden md:block"
          >
            <ChevronDoubleLeftIcon className="w-8 h-8 text-black duration-300 group-hover:text-white " />
          </button>

          {/* Scrollable Items Container */}
          <div
            ref={scrollContainerRef}
            className="flex gap-10 overflow-x-auto scroll-smooth scrollbar-hidden items-end"
            style={{ scrollBehavior: "smooth" }}
          >
            {categories.length > 0 ? (
              categories.map((category) =>(
                <div key={category.id} className="flex-shrink-0 lg:w-44 md:w-36 w-28">
                  <CustomCategoryCard image={category.img} title={category.title} />
                </div>
              ))
            ) : (
              <p className="w-full text-center text-gray-500">No categories found.</p>
            )}
          </div>

          {/* Right Scroll Button */}
          <button
            onClick={() => scroll("right")}
            className="absolute  z-10 p-2 -translate-y-1/2 bg-white duration-300 hover:bg-custom-yellow top-1/2 rounded-full shadow-md right-[0px] group hidden md:block"
          >
            <ChevronDoubleRightIcon className="w-8 h-8 font-bold text-black duration-300 group-hover:text-white" />
          </button>
        </div>

      </div>
    </div>
  );
};

export default ChooseItemsSection;
